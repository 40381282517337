<template>
	<div>
		<a-spin :spinning="loading">
			<div class="ft20 cl-black cl-main ftw500">运费模版</div>
			<div class="mt20">
				<div class="bg-w pd30" style="min-height: 800px;">
					<div >
						<a-form-model :model="form" :label-col="labelCol" :wrapper-col="wrapperCol">
							<a-form-model-item required label="模版名称" help="30字以内">
								<a-input v-model="form.name" placeholder="输入模版名称" style="width: 480px;"></a-input>
							</a-form-model-item>
							
							<a-form-model-item required label="发货时间" >
								<a-select v-model="form.hours" style="width: 160px;">
									<a-select-option :value="24">24小时</a-select-option>
									<a-select-option :value="48">48小时</a-select-option>
									<a-select-option :value="72">72小时</a-select-option>
								</a-select>
							</a-form-model-item>
							
							<a-form-model-item required label="邮费设置">
								<a-radio-group v-model="form.freight_type">
									<a-radio :value="1">全国包邮</a-radio>
									<a-radio :value="2">自定义邮费</a-radio>
								</a-radio-group>
							</a-form-model-item>
							
							<a-form-model-item required label="计价方式" v-if="form.freight_type==2">
								<a-radio-group v-model="form.valuation_type">
									<a-radio :value="1">按件</a-radio>
								</a-radio-group>
							</a-form-model-item>
							
							<a-form-model-item required label="默认运费" v-if="form.freight_type==2">
								<div class="flex alcenter">
									<a-input-number v-model="piece.base_piece_num"  placeholder="请输入" :precision="0" :min="0" style="width: 100px;"/>
									<div class="ml10 mr10 ft14 ftw400 cl-black">件内</div>
									<a-input-number v-model="piece.base_piece_price"  placeholder="请输入" :precision="2" :min="0" style="width: 100px;"/>
									<div class="ml10 mr10 ft14 ftw400 cl-black">元，每增加</div>
									<a-input-number v-model="piece.add_piece_num"  placeholder="请输入" :precision="0" :min="0" style="width: 100px;"/>
									<div class="ml10 mr10 ft14 ftw400 cl-black">件，增加</div>
									<a-input-number v-model="piece.add_piece_price"  placeholder="请输入" :precision="2" :min="0" style="width: 100px;"/>
									<div class="ml10 mr10 ft14 ftw400 cl-black">元</div>
								</div>
							</a-form-model-item>
							
							<a-form-model-item required label="指定地区" v-if="form.freight_type==2">
								<div v-for="(item,index) in piece.piece_data">
									<a-select v-model="item.designated_area"  mode="multiple" style="width: 400px;">
										<template v-for="(item,index) in provinces">
											<a-select-option :value="item" v-if="getDisplayArea.indexOf(item) == -1">{{item}}</a-select-option>
											<a-select-option :value="item" v-else disabled>{{item}}</a-select-option>
										</template>
									</a-select>
									
									<div class="flex alcenter mt10">
										<a-input-number v-model="item.base_piece_num"  placeholder="请输入" :precision="0" :min="0" style="width: 100px;"/>
										<div class="ml10 mr10 ft14 ftw400 cl-black">件内</div>
										<a-input-number v-model="item.base_piece_price"  placeholder="请输入" :precision="2" :min="0" style="width: 100px;"/>
										<div class="ml10 mr10 ft14 ftw400 cl-black">元，每增加</div>
										<a-input-number v-model="item.add_piece_num"  placeholder="请输入" :precision="0" :min="0" style="width: 100px;"/>
										<div class="ml10 mr10 ft14 ftw400 cl-black">件，增加</div>
										<a-input-number v-model="item.add_piece_price"  placeholder="请输入" :precision="2" :min="0" style="width: 100px;"/>
										<div class="ml10 mr10 ft14 ftw400 cl-black">元</div>
										<div class="ml10 clickAct" @click="delCustomPostage(index)">
											<i class="iconfont iconbtn_close"/>
										</div>
									</div>
								</div>
								
								<div>
									<a-button type="primary" icon="plus" ghost @click="addCustomPostage()">添加</a-button>
								</div>
							</a-form-model-item>
							
							<a-form-model-item required label="暂不发货地区"  >
								<a-select v-model="form.no_delivery_area" mode="multiple" style="width: 400px;" >
									<template v-for="(item,index) in provinces">
										<a-select-option :value="item" v-if="getDisplayArea.indexOf(item) == -1">{{item}}</a-select-option>
										<a-select-option :value="item" v-else disabled>{{item}}</a-select-option>
									</template>
									
								</a-select>
							</a-form-model-item>
							
							<a-form-model-item :wrapper-col="{ span: 14, offset: 4 }">
							      <a-button type="primary" @click="onSubmit" :loading="confirmLoading">
							        确定保存
							      </a-button>
							</a-form-model-item>
						</a-form-model>	
						
					</div>
				</div>
			</div>
		</a-spin>
	</div>
</template>

<script>
	import {provinces} from '../../common/router/province.js';
	export default{
		data(){
			return{
				loading:false,
				confirmLoading:false,
				labelCol: { span: 4 },
				wrapperCol: { span: 20 },
				provinces:[],
				form:{
					name:'',
					hours:'',
					freight_type:1,
					valuation_type:1,
					no_delivery_area:[],
				},
				piece:{
					base_piece_num:'',
					base_piece_price:'',
					add_piece_num:'',
					add_piece_price:'',
					piece_data:[],
				}
			}
		},
		
		created() {
			this.provinces=provinces;
			this.loaddata();
		},
		computed:{
			getDisplayArea(){
				let arr =  new Array;
				if(this.form.no_delivery_area.length>0){
					arr = this.joinArray(arr,this.form.no_delivery_area);
				}
				
				for(var a in this.piece.piece_data){
					if(this.piece.piece_data[a].designated_area.length>0){
						arr = this.joinArray(arr,this.piece.piece_data[a].designated_area)
					}
				}
				
				return arr;
			},
		},
		methods:{
			loaddata(){
				if(this.loading==true) return;
				this.loading=true;
				this.$http.api('admin/showFreightTemplate').then(res=>{
					this.form=res.detail;
					this.piece=res.piece;
					this.loading=false;
				}).catch(res=>{
					console.log(res);
					this.loading=false;
				})
			},
			
			
			joinArray(arr1,arr2){
				for( var a in arr2){
					if(arr1.indexOf(arr2[a]) == -1){
						arr1.push(arr2[a]);
					}
				}
				return arr1;
			},
			
			addCustomPostage(){
				this.piece.piece_data.push({
					designated_area:[],
					base_piece_num:'',
					base_piece_price:'',
					add_piece_num:'',
					add_piece_price:'',
				});
			},
			
			delCustomPostage(index){
				this.piece.piece_data.splice(index,1);
			},
			
			onSubmit(){
				if(this.confirmLoading==true) return;
				this.confirmLoading=true;
				this.$http.api('admin/saveFreightTemplate',{
					name:this.form.name,
					hours:this.form.hours,
					freight_type:this.form.freight_type,
					valuation_type:this.form.valuation_type,
					no_delivery_area:this.form.no_delivery_area,
					
					base_piece_num:this.piece.base_piece_num,
					base_piece_price:this.piece.base_piece_price,
					add_piece_num:this.piece.add_piece_num,
					add_piece_price:this.piece.add_piece_price,
					piece_data:JSON.stringify(this.piece.piece_data),
				}).then(res=>{
					this.$message.success('设置成功');
					this.confirmLoading=false;
					this.loaddata();
				}).catch(res=>{
					console.log(res);
					this.confirmLoading=false;
				})
			}
		}
	}
</script>

<style>
</style>
